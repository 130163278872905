<script setup>
import { toRefs } from "vue"

const props = defineProps({
  title: String,
  list: Object,
  lang:String,
})
const {  list, lang } = toRefs(props)
function convertirFecha(fechaISO) {
    if(!fechaISO)return null;
    return new Date(fechaISO).toLocaleDateString("en-US", { weekday: "short", day: "2-digit", month: "short", year: "numeric" });
}
</script>
<template>
  <div class="card rounded-0 p-0 shadow-sm border-0">
    <div class="card-header bg-white text-center">
      <h2 class="fw-bold  fs-6">
        {{ lang === 'en' ? 'Your Booking' : (lang === 'pt' ? 'Sua Reserva' : 'Tu Reserva') }}
      </h2>

    </div>
    <div class="card-body px-0">
 
      <div class="table-responsive">
  
        <table class="table  table-bordered table-sm">
          <tbody>
            <tr>
              <td>
                <strong>Tour</strong>
              </td>
              <td>
                <span v-html="list.tour"></span>
              </td>
            </tr>
            <tr>
              <td>
                <strong> {{ lang === 'en' ? 'Duration' : (lang === 'pt' ? 'Duração' : 'Duración') }}</strong>
              </td>
              <td>
                <span>{{ list.duration }} {{ list.duration == 1 ? 'Day' : 'Days' }}</span>

              </td>
            </tr>
            <tr>
              <td>
                <strong>{{ lang === 'en' ? 'Service' : (lang === 'pt' ? 'Serviço' : 'Servicio') }}</strong>
              </td>
              <td>
                <span>{{ list.typeService }}</span>
              </td>
            </tr>
            <tr>
              <td>
                <strong>{{ lang === 'en' ? 'Start date' : (lang === 'pt' ? 'data de início' : 'fecha de inicio') }}</strong>
              </td>
              <td>
                <span>{{ list.startDate  }}</span>
              </td>
            </tr>
            <tr>
              <td>
                <strong> {{ lang === 'en' ? 'Travelers' : (lang === 'pt' ? 'Viajantes' : 'Viajeros') }}</strong>
              </td>
              <td>
                <span>{{ list.travelers.length }}</span>
              </td>
            </tr>
            <!-- train up´grate -->
            <tr v-if="list.upgrateTrain">
                <td><strong>Train</strong></td>
                <td>{{ list.upgrateTrain.name }} - {{ list.upgrateTrain.price }} <small>USD</small> {{ lang === 'en' ? 'per person' : (lang === 'pt' ? 'por pessoa' : 'por persona') }} </td>
            </tr>
            <!-- extra hike -->
            <tr v-if="list.selectedItems" v-for="extra in list.selectedItems"   :key="extra.name">
              <td >
                  <strong>{{ extra.name }}</strong>
              </td>
              <td >
                  <span> {{ extra.price }} USD x {{ extra.quantity }}unit. = {{ extra.quantity * extra.price }} USD
                  </span>
              </td>
            </tr>
            <!-- } -->
            <!-- Extra Hike -->
            <tr v-if="list.extraHike">
                <td><strong>{{ lang === 'en' ? 'EXTRA HIKE' : (lang === 'pt' ? 'TRILHA EXTRA' : 'CAMINATA EXTRA') }}</strong></td>
                <td>{{ list.extraHike.name }} - {{ list.extraHike.price }} <small>USD</small> per person </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>