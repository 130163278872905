<script setup>
import { reactive, ref, onMounted, toRefs } from "vue";
import Trekking from './dataExtra.vue'

const props = defineProps({
  hasTrek: {
    type: Object,
    default: false,
  },
  lang:String,
})
const { hasTrek } = toRefs(props)
const open = ref(false);
const emit = defineEmits(['extraEmit'])
const dataEtras = reactive({
  selectedItems: [],
  upgrateTrain: { name: "Expedition Train (Included)", price: 0 },
  extraHike: null
});
const trekkingItems = ref([
  { name: "Sleeping Bag", price: 20, quantity: 0 },
  { name: "Air mattress", price: 20, quantity: 0 },
  { name: "Walking Poles", price: 15, quantity: 0 },
]);

const upgrateTrain = ref([
  { name: "Expedition Train (Included)", price: 0 },
  { name: "Vistadome Train", price: 67 },
  { name: " Vistadome Observatory", price: 100 },
  { name: "Hiram Bingham Train luxury ", price: 550 },
])

const extraHike = ref([
  { name: 'Huayna Picchu', price: 75 },
  { name: 'Machu Picchu Mountain', price: 75 },
  { name: 'None', price: 0 }
])

const showModal = () => {
  open.value = true;
};

const confirmExtras = () => {
  emit('extraEmit', dataEtras)
  open.value = false;
}
// cancel extras
const canEmit = () => {
  dataEtras.selectedItems = [],
    dataEtras.upgrateTrain = { name: "Expedition Train (Included)", price: 0 },
    dataEtras.extraHike = null,
    emit('extraEmit', dataEtras)
  open.value = false;
}
onMounted(() => {
  emit('extraEmit', dataEtras)
})
</script>

<template>
  <div class="py-2">
    <div class="text-end me-2">
      <span class="fw-bold text-dark d-block fw-bold mb-1">
        {{ lang === 'en' ? 'Click here for' : (lang === 'pt' ? 'Clique aqui para' : 'Haz clic aquí para') }}
       </span>
      <button type="button" class="btn btn-dark rounded-0 fw-bold letter-spacing-2 text-uppercase" @click="showModal">
        <i class="bi bi-plus-lg"></i> 
        {{ lang === 'en' ? 'Rentals and Add-ons' : (lang === 'pt' ? 'Aluguel e Extras' : 'Alquileres y Complementos') }}
      </button>
    </div>
    <a-modal v-model:open="open" class="p-1" :maskClosable="false">
      <template #title>
        <div class="text-center">
          <span class="text-dark fw-bold">
            {{ lang === 'en' ? 'UPGRADES AND EXTRAS' : (lang === 'pt' ? 'UPGRADES E EXTRAS' : 'ACTUALIZACIONES Y EXTRAS') }}
          </span>
        </div>
      </template>
      <template #footer>
        <div class="text-center">
          <!-- <a-popconfirm title="Are you sure delete this task?" @confirm="confirmExtras" @cancel="cancel" okText="Yes"
            cancelText="No"> -->
          <button type="button" @click="canEmit" class="btn btn-primary text-white rounded-0 px-3 me-2">
            {{ lang === 'en' ? 'Cancel' : (lang === 'pt' ? 'Cancelar' : 'Cancelar') }}
          </button>
          <button type="button" @click="confirmExtras" class="btn btn-primary text-white rounded-0 px-3">
            {{ lang === 'en' ? 'Confirm' : (lang === 'pt' ? 'Confirmar' : 'Confirmar') }}
          </button>
          <!-- </a-popconfirm> -->
        </div>
      </template>
      <!-- items -->
      <div>
        <!-- <Trekking :camino=""></Trekking> -->
        <!-- rental -->
        <div class="card p-0 border-0 rounded-0" v-if="hasTrek.hasHike">
          <div class="card-header p-2 bg-light">
            <h4 class="letter-spacing-1 mb-0 fw-bold text-dark text-uppercase fs-sm-9">
              {{ lang === 'en' ? 'Rental' : (lang === 'pt' ? 'Aluguel' : 'Alquiler') }}
            </h4>
          </div>
          <div class="card-body p-0">
            <p class="mt-2 mb-1">
              {{ lang === 'en' ? 'Please mark off which items you would like to rent from SAM Travel Peru' : (lang === 'pt' ? 'Marque os itens que você gostaria de alugar da SAM Travel Peru' : 'Por favor, marca los elementos que te gustaría alquilar de SAM Travel Peru') }}
            </p>
            <div class="">
              <div v-for="(item, index) in trekkingItems" :key="index" class="form-check d-flex flex-column flex-md-row">
                <div class="w-100 w-md-50">
                  <input class="form-check-input" type="checkbox" v-model="dataEtras.selectedItems" :value="item"
                    :id="item.name" />
                  <label class="form-check-label ms-1" :for="item.name">
                    {{ item.name }}
                  </label>
                  - ${{ item.price }} x {{ item.quantity }} = ${{ item.quantity * item.price }} USD
                </div>
                <div class="d-inline-block">
                  <div class="d-flex">
                    <button class="btn-plus ms-2 rounded-0" @click="item.quantity > 0 ? item.quantity-- : null"
                      :disabled="!dataEtras.selectedItems.includes(item)">
                      <span class="fw-bold">-</span>
                    </button>
                    <input type="text" readonly class="rounded-0 input-a mx-1 d-inline" v-model="item.quantity"
                      :disabled="!dataEtras.selectedItems.includes(item)" min="1" />
                    <button class="btn-plus rounded-0" @click="item.quantity++"
                      :disabled="!dataEtras.selectedItems.includes(item)">
                      <span class="fw-bold">+</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- upgrate train  -->
        <div class="card mt-2 p-0 border-0 rounded-0" v-if="hasTrek.noupgrate">
          <div class="card-header p-2 bg-light">
            <h4 class="letter-spacing-1 fw-bold mb-0 text-dark text-uppercase fs-sm-9">
              {{ lang === 'en' ? 'Upgrade' : (lang === 'pt' ? 'Atualização' : 'Actualizar') }}
            </h4>
          </div>
          <div class="card-body p-0">
            <p class="mt-2 mb-1">
              {{ lang === 'en' ? 'Please mark off which items you would like to rent from SAM Travel Peru' : (lang === 'pt' ? 'Marque os itens que você gostaria de alugar da SAM Travel Peru' : 'Marque los elementos que te gustaría alquilar de SAM Travel Peru') }}
            </p>
            <div class="">
              <div v-for="(item, index) in upgrateTrain" :key="index" class="form-check d-flex flex-column flex-md-row">
                <div class="w-100 w-md-50">
                  <input class="form-check-input" type="radio" v-model="dataEtras.upgrateTrain" :value="item"
                    :id="item.name" />
                  <label class="form-check-label ms-1" :for="item.name">
                    {{ item.name }}
                  </label>
                  - ${{ item.price }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Extra Hike -->
        <div class="card mt-2 p-0 border-0 rounded-0" v-if="hasTrek.noupgrate">
          <div class="card-header p-2 bg-light">
            <h4 class="letter-spacing-1 fw-bold mb-0 text-dark text-uppercase fs-sm-9">
              {{ lang === 'en' ? 'Extra Hike' : (lang === 'pt' ? 'Trilha Extra' : 'Caminata Extra') }}
            </h4>
          </div>
          <div class="card-body p-0">
            <p class="mt-2 mb-1">
              {{ lang === 'en' ? 'Only one hike is possible per day because of lack of time' : (lang === 'pt' ? 'Apenas uma trilha é possível por dia devido à falta de tempo' : 'Solo es posible una caminata por día debido a la falta de tiempo') }}
            </p>
            <div class="">
              <div v-for="(item, index) in extraHike" :key="index" class="form-check d-flex flex-column flex-md-row">
                <div class="w-100 w-md-50">
                  <input class="form-check-input" type="radio" v-model="dataEtras.extraHike" :value="item"
                    :id="item.name" />
                  <label class="form-check-label ms-1" :for="item.name">
                    {{ item.name }}
                  </label>
                  - ${{ item.price }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<style lang="scss">
.btn-plus {
  border: none;
  background: #ccc;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  font-size: 1.3rem;

  span {
    position: relative;
    top: -6px;
  }
}

.input-a {
  width: 30px;
  text-align: center;
  margin: 0 5px;
  border: 1px solid #b0b0b0;
  border-radius: 5px;
}

.w-100 {
  width: 100%;
}

.w-md-50 {
  @media (min-width: 992px) {
    width: 50% !important;
  }
}
</style>



