
<script setup>
import { ref, watch, onMounted, toRefs,defineEmits } from 'vue';
import InpurError from "../components/InputError.vue";

import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.css';
const props = defineProps({
    formdata: Object,
    // current_date:String,
    lang:String,
    hasValidate: {
        type: Boolean,
        default: false
    },
})
const emit = defineEmits(['selecttitle'])
const { formdata, hasValidate,lang } = toRefs(props)
const listours = ref([]);
const hasKeyTour = ref(false)
const hasid = ref(formdata.value.keytour);
const loading = ref(true);
const datestart=ref('');
const alterDate=ref('');
onMounted(async () => {
    flatpickr(datestart.value, {
        altInput: false,
        dateFormat: "d-m-Y",
        disableMobile:true,
        // altFormat: "DD-MM-YYYY",
        allowInput: true,
        minDate: "today"
    });
    flatpickr(alterDate.value, {
        altInput: false,
        dateFormat: "d-m-Y",
        disableMobile:true,
        // altFormat: "DD-MM-YYYY",
        allowInput: true,
        minDate: "today"
    });
    try {

        let categories = await fetch('https://www.samtravelperu.com/wp-json/wp/v2/categories?include=2085,2301,11,1,7,2303,8,3,2305&orderby=id&order=asc');

        //   let categories = await fetch("https://orangenationperu.com/wp-json/wp/v2/categories?exclude=11");
        let resCategory = await categories.json();
        let category = resCategory;
        let getpost = await fetch(`https://www.samtravelperu.com/wp-json/wp/v2/posts?categories_exclude=26,110,1107,27&per_page=100`);

        let post = await getpost.json();

        let foundPost = post.find(p => p.id === formdata.value.keytour);
        if (foundPost) {
            hasKeyTour.value = true;
        }
        if (!formdata.value.keytour) hasKeyTour.value = true;
        // console.log(post)
        listours.value = { category, post };
        loading.value = false;
    } catch (error) {
        console.log(error);
    }
})


// const handleChange = (event) => {
//     const foundPost = listours.value.post.find(element => element.id == event.target.value);
//     if (foundPost) {
//         emit('selecttitle',{  idIncluded:foundPost.id, link:foundPost.link,title:foundPost.title.rendered})
//         // formdata.tour = foundPost.title.rendered;
//     }
// };

const handleChange = (event) => {
    listours.value.category.forEach(function (cat) {
        listours.value.post.some(function (post) {
            if (cat.id == post.categories[0] && post.id == event.target.value) {
                // console.log(post.acf)
                emit('selecttitle', { catid:cat.id,numday: post.acf.duration, idIncluded: post.id, link: post.link, title: post.title.rendered });
                return true; // Exit the loop once the condition is met
            }
            return false;
        });
    });

};

watch(() => hasValidate.value, (newvalidate) => {
    hasValidate.value = newvalidate;
    console.log(hasValidate.value)

});

// hasketour


</script>

<template>
    <div>
        <div class="card rounded-0 p-0">

            <div class="card-header m-0 px-2 py-3 bg-white border-bottom-dotted">
                <h2 class="fw-bold fs-6 mb-0 text-primary">
                    {{ lang === 'en' ? "TOUR INFORMATION" : (lang === 'pt' ? "INFORMAÇÕES SOBRE O PASSEIO" : "INFORMACIÓN DEL TOUR") }}
                 </h2>
            </div>
            <div class="card-body">
                <div class="row gy-3">
                    <div class="col-md-12">
                        <label for="tours" class="form-label fw-bold">
                        {{ lang === 'en' ? "Which Tour(s)/Trek(s) are you booking?" : (lang === 'pt' ? "Quais passeios/trilhas você está reservando?" : "¿Qué tour(s)/trek(s) estás reservando?") }}
                        </label>
                        <a-skeleton-input v-if="loading" :loading="loading" active rows="1" :block="true">
                        </a-skeleton-input>
                        <div v-else>
                            <input v-if="!hasKeyTour" type="text" name="" class="form-control rounded-0" id=""
                                :value="formdata.tour" readonly>
                            <select v-else class="form-select rounded-0 fs-small letter-spacing-1 fw-500"
                                v-model="formdata.keytour" name="tour" aria-label="Default select example"
                                @change="handleChange" required :disabled="hasid ? true : false">
                                <optgroup v-for="(cat, index) in listours.category" :value="cat.name" :key="index"
                                    :label="cat.name">
                                    <template v-for="(post, index) in listours.post" :key="index">
                                        <option v-if="cat.id == post.categories[0]" :value="post.id">
                                            <span v-html="post.title.rendered"> </span>
                                            <!-- <span class="fs-sm"> ({{ post.acf.num_days}}D)</span> -->
                                        </option>
                                    </template>
                                </optgroup>
                            </select>
                        </div>

                        <!-- <a-select v-model:value="formdata.keytour" @change="handleChange" class="w-100 rounded-0"
                            :loading="listours.length == 0 ? true : false" show-search :disabled="hasid?true:false">
                            <a-select-opt-group v-for="(cat, index) in listours.category" :value="cat.name" :key="index"
                                :label="cat.name">
                                <template v-for="post in listours.post" :key="post.id">
                                    <a-select-option v-if="cat.id == post.categories[0]" :value="post.id" :key="post.id">
                                        <span v-html="post.title.rendered"></span>
                                    </a-select-option>
                                </template>
                            </a-select-opt-group>
                        </a-select>  -->
                        <InpurError :message="hasValidate == true && formdata.keytour === null ? 'Required Trek' : null" />
                    </div>
                    <!-- type tours -->
                    <div class="col-md-12">
                            <div>
                                <label for="tours" class="form-label fw-bold">
                                {{ lang === 'en' ? "Booking a group or private service?" : (lang === 'pt' ? "Reservando um serviço em grupo ou privado?" : "¿Reservando un servicio en grupo o privado?") }}
                                <a-popover :title="lang === 'en' ? 'Type Service' : (lang === 'pt' ? 'Tipo de Serviço' : 'Tipo de servicio')">
                                    <template #content>
                                    <div style="width: 300px">
                                        <span>{{ lang === 'en' ? 'A private tour means that there will be no other parties in your group, only your party, and your guide and trekking team. There are additional costs for private tours.' : (lang === 'pt' ? 'Um passeio privado significa que não haverá outras partes no seu grupo, apenas a sua festa, e sua equipe de guias e trekking. Existem custos adicionais para passeios privados.' : 'Un tour privado significa que no habrá otras partes en tu grupo, solo tu grupo, y tu guía y equipo de trekking. Hay costos adicionales para tours privados.') }}</span>
                                    </div>
                                    </template>
                                    <i type="button" class="bi bi-info-circle-fill fs-6 text-primary text-opacity-75"></i>
                                </a-popover>
                                </label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="inlineRadioOptions" v-model="formdata.typeService" id="inlineRadio1" value="Group" />
                                <label class="form-check-label" for="inlineRadio1">{{ lang === 'en' ? 'Group Service' : (lang === 'pt' ? 'Serviço em Grupo' : 'Servicio en grupo') }}</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="inlineRadioOptions" v-model="formdata.typeService" id="inlineRadio2" value="Private" />
                                <label class="form-check-label" for="inlineRadio2">{{ lang === 'en' ? 'Private Service' : (lang === 'pt' ? 'Serviço Privado' : 'Servicio privado') }}</label>
                            </div>
                            </div>
                            <div class="col-md-6">
                            <div class="">
                                <label for="tours" class="form-label fw-bold">
                                {{ lang === 'en' ? 'Start date' : (lang === 'pt' ? 'data de início' : 'fecha de inicio') }}
                                </label>
                                <input type="text" ref="datestart" required id="#myID" v-model="formdata.startDate" class="form-control rounded-0" :placeholder=" lang === 'en' ? 'Select Date' : (lang === 'pt' ? 'Selecione a Data' : 'Seleccionar fecha') ">
                                <!-- <InpurError :message="hasValidate == true && formdata.startDate === null ? " /> -->
                                <InpurError :message="hasValidate == true && formdata.keytour === null ? 'Required date Start' : null" />
                            </div>
                            </div>
                            <div class="col-md-6">
                            <div class="">
                                <label for="tours" class="form-label">
                                <span class="fw-bold">{{ lang === 'en' ? 'Alternative Date' : (lang === 'pt' ? 'Data Alternativa' : 'Fecha alternativa') }}</span>
                                <small>({{ lang === 'en' ? 'Optional' : (lang === 'pt' ? 'Opcional' : 'Opcional') }})</small>
                                <a-popover :title="lang === 'en' ? 'Alternative Date' : (lang === 'pt' ? 'Data Alternativa' : 'Fecha alternativa')">
                                    <template #content>
                                    <div style="width: 300px">
                                        <span>{{ lang === 'en' ? 'Please provide an alternative start date if possible. Leave it blank if there are no other dates available for you.' : (lang === 'pt' ? 'Forneça uma data de início alternativa, se possível. Deixe em branco se não houver outras datas disponíveis para você.' : 'Proporcione una fecha de inicio alternativa si es posible. Déjelo en blanco si no hay otras fechas disponibles para usted.') }}</span>
                                    </div>
                                    </template>
                                    <i type="button" class="bi bi-info-circle-fill fs-6 text-primary text-opacity-75"></i>
                                </a-popover>
                                </label>
                                <input type="text" ref="alterDate" :placeholder="lang === 'en' ? 'Select Date' : (lang === 'pt' ? 'Selecione a Data' : 'Seleccionar fecha')"  id="#alterdate" v-model="formdata.alterDate" class="form-control rounded-0">
                            </div>
                     </div>
                </div>
            </div>
        </div>
    </div>
</template>
  

  

  <!-- Componente Padre -->
