
<script setup>
import { Modal } from 'ant-design-vue';
import TextInput from "../components/InputText.vue";
import InpurError from "../components/InputError.vue";
import { ref, watch, toRefs, createVNode,h } from 'vue';
const [modal, contextHolder] = Modal.useModal();

const props = defineProps({
  formdata: Object,
  lang:String
});
const emit = defineEmits(['addtraveleremit', 'deletetraveleremit']);
const { formdata, lang } = toRefs(props);

const activeKey = ref(['1','2','3', '4', '5', '6', '7', '8', '9', '10']);
const spinning = ref(false);

const addTravelers = async () => {
  spinning.value = true;
  const newTraveler = {
    fullname: '',
    gender: 'Male',
    docType: 'Passport',
    docNumber: '',
    birthdate: '',
    country: '',
    email: '',
    estudent: 'No',
    food: 'No',
    foodDes: '',

  };
  setTimeout(() => {
    emit('addtraveleremit', newTraveler)
    spinning.value = false;
  }, 1000)

};


const handleClick = (index, event) => {
  // spinning.value = true;
  event.stopPropagation();
       Modal.confirm({

        title: '¿Are you sure to want to delete de passenger nro.' + (index + 1),
        // content: 'When clicked the OK button, this dialog will be closed after 1 second',
        onOk() {
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              if (Math.random() > 1) {
                resolve();
                // Emitimos el evento 'deletetraveleremit' con el índice
                emit('deletetraveleremit', index);
              } else {
                reject();
                emit('deletetraveleremit', index);
              }
            }, 1000);
          }).catch(() => {
            // Handle el rechazo de la promesa
          });
        },
        onCancel() {
          // Lógica para el botón Cancelar
        },
      });

}



</script>
<template>
  <div>
    <div class="card mt-2 rounded-0">
      <div class="card-header card-header m-0 px-2 py-3 bg-white border-bottom-dotted">
        <h2 class="fw-bold fs-6 mb-0 text-primary">
          {{ lang === 'en' ? 'PASSENGER INFORMATION' : (lang === 'pt' ? 'INFORMAÇÕES DO PASSAGEIRO' : 'INFORMACIÓN DEL PASAJERO') }}
        </h2>
        <p class="mb-0 text-muted mt-1">
          {{ lang === 'en' ? 'Passport information to purchase tickets. Accurate and complete information is required by the Peruvian government.' : (lang === 'pt' ? 'Informações do passaporte para comprar passagens. Informações precisas e completas são necessárias pelo governo peruano.' : 'Información del pasaporte para comprar boletos. La información precisa y completa es requerida por el gobierno peruano.') }}
        </p>
      </div>
      <div class="card-body px-2">
        <div>
          <a-spin :spinning="spinning" tip="Loading...">
            <a-collapse v-model:activeKey="activeKey" class="rounded-0 p-0">
              <a-collapse-panel v-for="(traveler, index) in  formdata.travelers" :key="index + 1">
                <template #header>
                  <div class="p-0">
                    <h3 class="text-danger fs-sm-9">
                       {{ lang === 'en' ? 'Traveler ' + (index + 1) : (lang === 'pt' ? 'Viajante ' + (index + 1) : 'Viajero ' + (index + 1)) }}
                    </h3>
                  </div>
                </template>
                <template #extra>

                  <button type="button" @click="handleClick(index, $event)"
                    class="btn btn-danger btn-sm rounded-0 position-relative z-index-1">
                    <i class="bi bi-x-lg"></i>
                  </button>
                </template>
                <!--  -->
                <div class="row gy-2">
                  <!-- fullname -->
                  <div class="col-md-5">
                    <label for="name" class="form-label fs-sm-9 fw-bold">
                      {{ lang === 'en' ? 'Full Name' : (lang === 'pt' ? 'Nome Completo' : 'Nombre Completo') }}
                    </label>
                    <TextInput type="text" id="name"
                      @focus="$event.target.classList.toggle('border-danger', traveler.fullname.trim() === '')" 
                      @input="$event.target.classList.toggle('border-danger', traveler.fullname.trim() === '')" 
                       ref="name" v-model="traveler.fullname" required />
                  </div>
                  <!-- email -->
                  <div class="col-md-4">
                    <div>
                      <label for="email" class="form-label fs-sm-9 fw-bold">Email</label>
                      <TextInput type="email" id="email" 
                      @focus="$event.target.classList.toggle('border-danger', traveler.email.trim() === '')" 
                      @input="$event.target.classList.toggle('border-danger', traveler.email.trim() === '')" 
                      v-model="traveler.email" required />
                    </div>
                  </div>
                  <!-- Country -->
                  <div class="col-md-3">
                    <div>
                      <label for="Country" class="form-label fs-sm-9 fw-bold">{{ lang === 'en' ? 'Country' : (lang === 'pt' ? 'País' : 'País') }}</label>
                      <TextInput type="text" id="Country" v-model="traveler.country" required />
                    </div>
                  </div>
                  <!-- type doct -->
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-6">
                        <label for="name" class="form-label fs-sm-9 fw-bold">
                          {{ lang === 'en' ? 'Doc. Type' : (lang === 'pt' ? 'Tipo de Documento' : 'Tipo de Documento') }}
                        </label>
                        <select class="form-select rounded-0" v-model="traveler.docType" aria-label="Default select example">
                          <option value="Passport">Passport</option>
                          <option value="DNI/ID">DNI/ID</option>
                        <option value="Driver License">Driver License</option>
                      </select>
                     </div>
                      <div class="col-md-6">
                        <label for="name" class="form-label fs-sm-9 fw-bold">
                          {{ traveler.docType }} {{ lang === 'en' ? 'Number' : (lang === 'pt' ? 'Número' : 'Número') }}
                        </label>
                        <input type="text" v-model="traveler.docNumber" class="form-control rounded-0" required>
                      </div>
                    </div>
                  </div>
                  <!-- birt date -->
                  <div class="col-md-6">
                    <div>
                      <label for="name" class="form-label fs-sm-9 fw-bold">
                        {{ lang === 'en' ? 'Date of Birth' : (lang === 'pt' ? 'Data de Nascimento' : 'Fecha de Nacimiento') }}
                      </label>
                      <a-date-picker size="large" format="YYYY-MM-DD" class="rounded-0 w-100 form-label"
                        v-model:value="traveler.birthdate" :status="traveler.birthdate?'success':'error'"></a-date-picker>
                    </div>
                  </div>
                  <!-- dd -->
                  <div class="col-md-4">
                    <div>
                      <div>
                        <label for="tours" class="form-label fs-sm-9 fw-bold">
                          {{ lang === 'en' ? 'Gender' : (lang === 'pt' ? 'Gênero' : 'Género') }}
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" v-model="traveler.gender" :name="'gender' + index"
                          :id="'Male' + index" value="Male">
                        <label class="form-check-label" :for="'Male' + index">{{ lang === 'en' ? 'Male' : (lang === 'pt' ? 'Masculino' : 'Masculino') }}</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" v-model="traveler.gender" :name="'gender' + index"
                          :id="'Female' + index" value="Female">
                        <label class="form-check-label" :for="'Female' + index">{{ lang === 'en' ? 'Female' : (lang === 'pt' ? 'Feminino' : 'Femenino') }}</label>
                      </div>
                    </div>
                  </div>
                  <!-- student -->
                  <div class="col-md-4">
                    <div>
                      <div>
                        <label for="tours" class="form-label fs-sm-9 fw-bold">
                          {{ lang === 'en' ? 'Are you a student?' : (lang === 'pt' ? 'Você é estudante?' : '¿Eres estudiante?') }}
                          <a-popover title="Are you student?" >
                                    <template #content>
                                        <div style="width: 300px">
                                            <span >If you answer yes, we need a copy of their passport right away or we cannot get the student discount. Please email to info@samtravelperu.com</span>
                                        </div>
                                    </template>
                                    <i type="button" class="bi bi-info-circle-fill fs-6  text-primary text-opacity-75"></i>
                                </a-popover>
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" v-model="traveler.estudent" :name="'student' + index"
                          :id="'Yes' + index" value="Yes">
                        <label class="form-check-label" :for="'Yes' + index">{{ lang === 'en' ? 'Yes' : (lang === 'pt' ? 'Sim' : 'Sí') }}</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" v-model="traveler.estudent" :name="'student' + index"
                          :id="'No' + index" value="No">
                        <label class="form-check-label" :for="'No' + index">{{ lang === 'en' ? 'No' : (lang === 'pt' ? 'Não' : 'No') }}</label>
                      </div>
                    </div>
                  </div>
                  <!-- food -->
                  <div class="col-md-4">
                    <div>
                      <div>
                        <label for="tours" class="form-label fs-sm-9 fw-bold">
                          {{ lang === 'en' ? 'Food Restrictions / Allergies?' : (lang === 'pt' ? 'Restrições Alimentares / Alergias?' : 'Restricciones Alimenticias / Alergias?') }}
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" v-model="traveler.food" :name="'food' + index"
                          :id="'food0' + index" value="Yes">
                        <label class="form-check-label" :for="'food0' + index">{{ lang === 'en' ? 'Yes' : (lang === 'pt' ? 'Sim' : 'Sí') }}</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" v-model="traveler.food" :name="'food' + index"
                          :id="'food' + index" value="No">
                        <label class="form-check-label" :for="'food' + index">{{ lang === 'en' ? 'No' : (lang === 'pt' ? 'Não' : 'No') }}</label>
                      </div>
                    </div>
                    <!-- foo descriptios -->
                    <div class="" v-if="traveler.food=='Yes'">
                        <textarea v-model="traveler.foodDes" class="form-control fs-sm"
                          placeholder="Add any dietary restrictions you have or special dietary requirements here!"
                          id="floatingTextarea2" style="height: 100px"></textarea>                        
                    </div>
                  </div>
                </div>
              </a-collapse-panel>
            </a-collapse>
            <div class="text-end py-2">
              <button type="button" @click="addTravelers" class="btn btn-primary fs-sm letter-spacing-1  text-uppercase text-white rounded-0 fs-sm-6"> <i class="bi bi-plus-lg"></i>
                {{ lang === 'en' ? 'Add Traveler' : (lang === 'pt' ? 'Adicionar Viajante' : 'Agregar Viajero') }}
              </button>
            </div>
          </a-spin>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.ant-picker-focused {
  border-color: #ea541a !important;
  box-shadow: 0 0 0 2px rgb(234 84 26 / 8%);
  border-inline-end-width: 1px;
  outline: 0;
}

:where(.css-dev-only-do-not-override-185kyl0).ant-picker:hover,
:where(.css-dev-only-do-not-override-185kyl0).ant-picker-focused {
  border-color: #dee2e6 !important;
  border-inline-end-width: 1px !important;
}
</style>

