<script setup>
import { reactive, ref, toRefs } from "vue";
const props = defineProps({
    include: {
        type: Object,
        default: null
    },
});

const { include } = toRefs(props);
const temValueHotels = ref({ "Cusco": true, "Ollantaytambo": false, "Machu Picchu": true });
const dataHotel = reactive({
    selectHotels: []
})

const hotels = ref({
    Cusco: [
        { name: 'Cusco hotel 1', price: 250 },
        { name: 'Cusco hotel 2', price: 100 }
    ],
    Ollantaytambo: [
        { name: 'Ollantaytambo hotel 1', price: 300 },
        { name: 'Ollantaytambo hotel 2', price: 500 }
    ],
    'Machu Picchu': [
        { name: 'Machu Picchu hotel 1', price: 520 },
        { name: 'Machu Picchu hotel 5', price: 100 }
    ],
    'Lima': [
        { name: 'Lima 1', price: 520 },
        { name: 'Lima hotel 5', price: 100 }
    ]
});
//  creame un lista de hoteles 

// // 
const isObjectArray = (obj) => {
    if (obj === null) {
        return false;
    }
    // Recorre las propiedades del objeto
    for (const key in obj) {
        if (obj.hasOwnProperty(key) && obj[key] === true) {
            return true; // Si al menos una propiedad es true, retorna true
        }
    }
    return false; // Si todas las propiedades son false, retorna false
};


// change RADIO INPUT
const handleRadioChange = (location, hotelInfo) => {

    const TemHotel = { [location]: { ...hotelInfo } };
    temValueHotels.value[location] = TemHotel[location];

    const existingHotel = dataHotel.selectHotels.find((obj) => location in obj);
    if (existingHotel) {
        const newHotel = {
            [location]: { ...dataHotel.selectHotels.find((obj) => location in obj)[location], ...hotelInfo }
        };
        const index = dataHotel.selectHotels.findIndex((obj) => location in obj);
        if (index !== -1) {
            // Si se encuentra un objeto con la misma ubicación, reemplazarlo
            dataHotel.selectHotels[index] = newHotel;

        }
    }

};
// change CHECKBOX
const handleCheckboxChange = (location, checked) => {
    if (checked) {
        let isNewArray = isObject(temValueHotels.value[location]) ? temValueHotels.value[location] : null;
        // agregar a dataHotel si checked  existe en radio buttom
        dataHotel.selectHotels.push({ [location]: isNewArray });
    } else {
        const index = dataHotel.selectHotels.findIndex(hotel => location in hotel);
        if (index > -1) {
            dataHotel.selectHotels.splice(index, 1);
        }
    }
};
function isObject(value) {
    return value !== null && typeof value === 'object';
}
// test

</script>

<template>
    <div class="my-2" v-if="isObjectArray(include)" >
        <!-- {{ dataHotel.selectHotels }}
        <br>
        {{ temValueHotels }} -->
        <div class="card" v-for="(hotelsInLocation, location) in hotels" :key="location"
            :class="include[location] ? 'dd' : 'd-none'">
            <div class="card-header" v-if="include[location]">
                <input class="form-check-input" type="checkbox" :name="location"
                    @change="handleCheckboxChange(location, $event.target.checked)" :id="location" />
                <label class="form-check-label ms-1" :for="location">
                    {{ location }}
                </label>
            </div>
            <div class="card-body" v-if="include[location]">
                <div v-for="(hotel, index) in hotelsInLocation" :key="index">
                    <input class="form-check-input" type="radio" :name="location"
                        @change="handleRadioChange(location, hotel)" :value="hotel"  :id="hotel.name" />
                    <label class="form-check-label ms-1" :for="hotel.name">
                        {{ hotel.name }}
                    </label>

                </div>
            </div>
        </div>
       
    </div>
</template>
 





