<script setup>
import { ref, watch, toRefs, createVNode } from 'vue';
import TextInput from "../components/InputText.vue";
import InputArea from "../components/InputArea.vue";

const props = defineProps({
    formdata: Object,
    lang:String
});

const files = ref(null);
const getfilelist = ref(null);
const { formdata } = toRefs(props);
const emit = defineEmits(['setimage']);

const uploadfile = (event) => {
    let inputfiles = event.target.files;
    if (!Array.isArray(getfilelist.value)) {
        getfilelist.value = []; // Si no es un array, lo inicializamos como un array vacío
    }
    getfilelist.value = getfilelist.value.concat(Array.from(inputfiles));
    emit('setimage', getfilelist.value); // Emitir un evento con la
}
const handleRemove = index => {
    if (Array.isArray(getfilelist.value) && getfilelist.value.length > 0) {
        if (index >= 0 && index < getfilelist.value.length) {
            getfilelist.value.splice(index, 1); // Eliminar 1 elemento en la posición 'index'
            emit('setimage', getfilelist.value); // Emitir evento con la lista actualizada
        } else {
            console.error('Índice fuera de rango.');
        }
    } else {
        emit('setimage', null); // Si el array está vacío, emitir null
    }
};
</script>

<template>
    <div class="card mt-2 rounded-0">
        <div class="card-header m-0 px-2 py-3 bg-white border-bottom-dotted">
            <h2 class="fw-bold fs-6 mb-0 text-primary text-uppercase fs-sm-9">
                {{ lang === 'en' ? 'Additional information passengers' : (lang === 'pt' ? 'Informações adicionais dos passageiros' : 'Información adicional de los pasajeros') }}
            </h2>
        </div>
        <div class="card-body px-2">
            <div class="row gy-3">
                <div class="col-md-12">
                    <label for="information" class="form-label fs-sm-9 fw-bold">
                        {{ lang === 'en' ? 'Please add any other information you want SAM Travel Peru to be aware of.' : (lang === 'pt' ? 'Adicione qualquer outra informação que você gostaria que a SAM Travel Peru soubesse.' : 'Añade cualquier otra información que desees que SAM Travel Peru tenga en cuenta.') }}of.
                    </label>
                    <InputArea id="information" ref="information" v-model="formdata.adicionalInformation" required />
                </div>
                   
                <div class="col-md-12">
                    <label for="typeroom" class="form-label fs-sm-9 fw-bold">
                        {{ lang === 'en' ? 'Type Room?' : (lang === 'pt' ? 'Tipo de Quarto?' : '¿Tipo de Habitación?') }}
                         <small class="text-muted">(Optional)</small>
                      </label>
                      <select name="typeroom" id="typeroom" v-model="formdata.typeRoom" class="form-select rounded-0">
                        <option value="">{{ lang === 'en' ? 'Select room type' : (lang === 'pt' ? 'Selecionar tipo de quarto' : 'Seleccionar tipo de habitación') }}</option>
                        <option value="Single - Room">{{ lang === 'en' ? 'Single - Room' : (lang === 'pt' ? 'Quarto Individual' : 'Habitación Individual') }}</option>
                        <option value="Double bed Room">{{ lang === 'en' ? 'Double bed Room' : (lang === 'pt' ? 'Quarto com cama de casal' : 'Habitación con cama doble') }}</option>
                        <option value="Matrimonial Room">{{ lang === 'en' ? 'Matrimonial Room' : (lang === 'pt' ? 'Quarto Matrimonial' : 'Habitación Matrimonial') }}</option>
                      </select>
                      <InputArea id="hotellocation" class="mt-1 ms-2" ref="hotellocation" v-model="formdata.typeRoomInfo" placeholder="Additional information about the type of Rooms" />
                </div>

                <div class="col-md-12">
                    <label for="hotellocation" class="form-label fs-sm-9 fw-bold">
                        {{ lang === 'en' ? 'If you have already booked your hotel in Cusco, can you please provide the name and address of the hotel for pick up?' : (lang === 'pt' ? 'Se você já reservou seu hotel em Cusco, por favor, forneça o nome e endereço do hotel para a retirada.' : 'Si ya has reservado tu hotel en Cusco, ¿puedes proporcionar el nombre y la dirección del hotel para la recogida?') }}
                    </label>
                    <InputArea id="hotellocation" ref="hotellocation" v-model="formdata.hotellocation" required  />
                </div>
                <div class="col-md-12">
                    <label for="emergency" class="form-label fs-sm-9 fw-bold">
                        {{ lang === 'en' ? 'Please fill in names and phone numbers for two emergency contacts who will not be with you on the tour or trek' : (lang === 'pt' ? 'Por favor, preencha os nomes e números de telefone de dois contatos de emergência que não estarão com você no passeio ou trekking' : 'Por favor, completa los nombres y números de teléfono de dos contactos de emergencia que no estarán contigo en el recorrido o trekking') }}
                    </label>
                    <TextInput type="text" v-model="formdata.numberEmergency" id="emergency" ref="emergencyNumber"
                        required />
                </div>
                <div class="col-md-12">
                    <label for="aboutsam" class="form-label fs-sm-9 fw-bold">
                        {{ lang === 'en' ? 'How did you hear about SAM Travel Peru?' : (lang === 'pt' ? 'Como você ficou sabendo da SAM Travel Peru?' : '¿Cómo te enteraste de SAM Travel Peru?') }}
                    </label>
                    <TextInput type="text" v-model="formdata.aboutSam" id="aboutsam" ref="aboutsam" />
                </div>
                <!-- files -->
                <div class="col-md-12">
                    <label for="aboutsam" class="form-label d-block fs-sm-9 fw-bold">
                        {{ lang === 'en' ? 'Attach Your Passport Copies' : (lang === 'pt' ? 'Anexe as cópias do seu passaporte' : 'Adjunta las copias de tu pasaporte') }}
                        <a-popover title="Attach Your Passport Copies optional ">
                                    <template #content>
                                    <div style="width: 300px">
                                        <span>Please note that attaching a copy of your passport is optional in this reservation form. However, it is important for facilitating ticket purchase and ensuring the validity of your details. Additionally, we remind you that when visiting Machu Picchu or other archaeological sites, it is necessary to present the same passport you provided in this form for entry. Thank you!</span>
                                    </div>
                                    </template>
                                    <i type="button" class="bi bi-info-circle-fill fs-6 text-primary text-opacity-75"></i>
                        </a-popover>
                    </label>      
                    <button type="button" class=" rounded-0 border-dashed px-4 btn btn-outline-dark border-1 " @click="files.click()">
                        <div class="d-flex align-items-center">
                            <i class="bi bi-cloud-upload fs-5 me-2"></i>
                            <h3 class="fs-6 mb-0">
                                {{ lang === 'en' ? 'Click to upload File' : (lang === 'pt' ? 'Clique para fazer upload do arquivo' : 'Haz clic para cargar el archivo') }}
                            </h3>
                            <input class="d-none" type="file" ref="files" @change="uploadfile" name="file" accept=".jpg, .jpeg, .png, .pdf" multiple />

                        </div>                       
                    </button>
                    <ul class="p-0 list-style-none">
                            <li class="fs-sm p-1 d-inline-block text-muted" v-for="(filess,index) in getfilelist" :key="index" >
                                {{ filess.name }}
                                <a-popover>
                                    <template #content>
                                        {{  'Delete file' }}
                                    </template>
                                    <span type="button" @click="handleRemove(index)" class="text-danger ms-1"><i
                                            class="bi bi-trash3-fill"></i></span>
                                </a-popover>
                            </li>
                        </ul>
                </div>
                <div class="col-md-12">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" v-model="formdata.Term" id="flexCheckDefault"
                            required>
                        <label class="form-check-label fw-bold" for="flexCheckDefault">
                            {{ lang === 'en' ? 'By checking the box, you agree to SAM Travel Peru ' : (lang === 'pt' ? 'Ao marcar a caixa, você concorda com os ' : 'Al marcar la casilla, aceptas los ') }}
                            <a :href="lang== 'en' ?'https://www.samtravelperu.com/sam-travel-peru-terms-conditions/' : (lang=== 'pt' ? 'https://orangenationperu.com/pt/termos-e-condicoes/' : 'https://www.samtravelperu.com/sam-travel-peru-terms-conditions/')" target="_blank" class="text-primary">
                                {{ lang === 'en' ? "Terms & Conditions" : (lang === 'es' ? "Términos y Condiciones de SAM Travel Peru" : "Termos e Condições da SAM Travel Peru") }}
                            </a>


                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.border-dashed{
    border: dashed 1px #888888;
}
</style>