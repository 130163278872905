<script setup>
import { ref, toRefs, defineProps, watch } from 'vue';
import TextInput from "../components/InputText.vue";
// import { VueTelInput } from 'vue-tel-input'
// import 'vue-tel-input/vue-tel-input.css';


const props = defineProps({
  formdata: Object,
  lang:String
});

const { formdata,lang } = toRefs(props);
 const emit=defineEmits('setContactInformation');

const selectedTravelerIndex = ref(null); // Initialize with the default index or the desired initial value
const selectedTraveler = ref([])//ref(formdata.value.travelers[selectedTravelerIndex.value]);

watch(() => selectedTravelerIndex.value, (newIndex) => {
  selectedTraveler.value = formdata.value.travelers[newIndex];
   emit('setContactInformation',{name:selectedTraveler.value.fullname ,email:selectedTraveler.value.email})

});

</script>

<template>
  <div>
    <div class="card mt-2 rounded-0">
      <div class="card-header m-0 px-2 py-3 bg-white border-bottom-dotted">
        <h2 class="fw-bold fs-6 mb-0 text-primary">
          {{ lang === 'en' ? 'CONTACT INFORMATION' : (lang === 'pt' ? 'INFORMAÇÕES DE CONTATO' : 'INFORMACIÓN DE CONTACTO') }}
        </h2>
        <p class="mb-0 text-muted mt-1">
          {{ lang === 'en' ? 'What is the email address of the person for the main Point Of Contact (POC) in your group?' : (lang === 'pt' ? 'Qual é o endereço de e-mail da pessoa responsável pelo ponto principal de contato (POC) em seu grupo?' : '¿Cuál es la dirección de correo electrónico de la persona responsable del Punto de Contacto Principal (POC) en tu grupo?') }}
        </p>
        <br>
      </div>
      <div class="card-body px-2">
        <div class="row">
          <div class="col-md-4">
            <label for="ctraveles" class="form-label"> {{ lang === 'en' ? 'Traveler' : (lang === 'pt' ? 'Viajante' : 'Viajero') }}</label>
            <TextInput type="text" v-model="formdata.name" placeholder="Traveler's name" :required="true" />
            <!-- <select v-model="selectedTravelerIndex" @change="getContactMain" class="form-select rounded-0" id="ctraveles" required> 
              <option v-for="(traveler, index) in formdata.travelers" :key="index" :value="index">
                {{ traveler.fullname }}
              </option>
            </select> -->
          </div>
          <div class="col-md-4">
             <label for="ctraveles" class="form-label">Email</label>
             <TextInput type="email" v-model="formdata.email" placeholder="Traveler's Email" :required="true" />
          </div>
          <div class="col-md-4">
            <label for="phonecotact" class="form-label"> {{ lang === 'en' ? 'Phone' : (lang === 'pt' ? 'Telefone' : 'Teléfono') }}</label>
            <TextInput type="text"  v-model="formdata.phone" id="phonecotact"
               :required="true" placeholder="Traveler's phone"></TextInput>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>




