// import { parse } from 'node-html-parser';
import { createApp  } from 'vue'
import {  DatePicker,Collapse,Spin,Popconfirm,Modal,Popover,Select,Upload,Skeleton  } from 'ant-design-vue';
import booking from './App.vue'
import 'ant-design-vue/dist/reset.css';

const app = createApp({
    components: {
        booking
    }
  });
  app.use(DatePicker)
  app.use(Spin)
  app.use(Popconfirm)
  app.use(Collapse)
  app.use(Modal)
  app.use(Popover)
  app.use(Select)
  app.use(Skeleton)
  app.use(Upload)
  app.mount("#book");


